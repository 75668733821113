import { action, observable, runInAction, configure } from "mobx";
import { FormSettingApi } from "apis";
import ApiStore from "./ApiStore";
import BaseStore from "./BaseStore";
import { CONFIG_FILE_EDITION, PREBUILT_MODELS } from "../core/utils/constants";

configure({ enforceActions: "observed" });
class FormSettingStore extends BaseStore {
  @observable keepFilterState = false;
  @observable formEdition = "Item";
  @observable fullFormSettings = [];
  @observable sortListFormSettings = [];
  @observable listFormSettings = [];
  @observable formSetting = {};
  @observable formSettingDefault = {
    id: -1,
    formEdition: "Item",
    settingName: "",
    settingBody: {
      // basic_setting
      FILE_IDENFICATION_TYPE: 0,
      OCR_LANG: "Japanese",
      PAGE_SEG_MODE: 4,
      RESCAN_THRESHOLD: 0,
      use_dup_char_reducer: false,
      reduce_target: '0,1,2,3,4',
      PROC_MAX_PAGE: 0,
      // image_processing
      DE_NOISE: false,
      IS_SLOPE_CORRECTION: true,
      AUTO_ROTATION: false,
      SKIP_LINE_REMOVE: false,
      LINE_REMOVAL_THRESHOLD: 0,
      LINE_REMOVAL_MULTIPLE_BY_TEXT_HEGHT: 3,
      HOUGH_THRESHOLD: 500,
      RESTORE_TEXT: false,
      THRESH_VALUE: 0,
      BINTHRESH_ON_LINE_REMOVAL: 200,
      BLACK_WHITE_THRESHOLD: 75,
      SHARPEN_VALUE: 0,
      SHORT_LINE_THRESH_H: 30,
      SHORT_LINE_THRESH_V: 30,
      USE_SHORT_DOTLINE_REMOVAL: false,
      THIN_LINE_REMOVAL_THRESHOLD_W: 3,
      THIN_LINE_REMOVAL_THRESHOLD_H: 3,
      ERODE_THIN_LINE_W: 12,
      ERODE_THIN_LINE_H: 7,
      CONV_TIFF_DPI: false,
      EXTRACTION_COLORS: '',
      REMOVAL_COLORS: '',
      FILTER_COLOR_BEFORE_LINEREMOVAL: false,
      LINE_EXTENSION_LEN: 0,
      ROUNDED_CORNER_THRESHOLD: 30,
      ROUNDED_CORNER_PADDING: 10,
      REMOVE_DOTTED_BACKGROUND: false,
      DOT_SIZE_THRESHOLD: 4,
      REMOVE_SMALL_RECTANGLES: false,
      REMOVE_RECTANGLE_MIN_AREA_THRESHOLD: 1200,
      REMOVE_RECTANGLE_MAX_AREA_THRESHOLD: 13000,
      REMOVE_DIAGONAL_LINES: false,
      // rule_specification
      COMPONENT_LEVEL: "ITEM",
      OCR_PDF_WITH_TEXT: true,
      USE_DL_STRING_DETECTION: true,
      STRING_DETECTION_SPLIT_LARGE_RECTANGLE: true,
      BLACK_LIST_EXT: '',
      LINKED_ITEM_THRESHOLD: 2,
      SPACE_MODE: 1,
      REDUCE_IMAGE_BEFORE_OCR: false,
    }
  };
  @observable prebuiltTable = [];
  @observable prebuiltInvoiceDefault = [
    {
      id: 1,
      defaultItemName: "CustomerName",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.customer_name",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 2,
      defaultItemName: "CustomerId",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.customer_id",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 3,
      defaultItemName: "PurchaseOrder",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.purchase_order",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 4,
      defaultItemName: "InvoiceId",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.invoice_id",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 5,
      defaultItemName: "InvoiceDate",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.invoice_date",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 6,
      defaultItemName: "DueDate",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.due_date",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 7,
      defaultItemName: "VendorName",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.vendor_name",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 8,
      defaultItemName: "VendorTaxId",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.vendor_tax_id",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 9,
      defaultItemName: "VendorAddress",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.vendor_address",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 10,
      defaultItemName: "VendorAddressRecipient",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.vendor_address_recipient",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 11,
      defaultItemName: "CustomerAddress",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.customer_address",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 12,
      defaultItemName: "CustomerTaxId",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.customer_tax_id",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 13,
      defaultItemName: "CustomerAddressRecipient",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.customer_address_recipient",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 14,
      defaultItemName: "BillingAddress",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.billing_address",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 15,
      defaultItemName: "BillingAddressRecipient",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.billing_address_recipient",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 16,
      defaultItemName: "ShippingAddress",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.shipping_address",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 17,
      defaultItemName: "ShippingAddressRecipient",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.shipping_address_recipient",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 18,
      defaultItemName: "PaymentTerm",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.payment_term",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 19,
      defaultItemName: "SubTotal",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.sub_total",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 20,
      defaultItemName: "TotalTax",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.total_tax",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 21,
      defaultItemName: "InvoiceTotal",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.invoice_total",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 22,
      defaultItemName: "AmountDue",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.amount_due",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 23,
      defaultItemName: "ServiceAddress",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.service_address",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 24,
      defaultItemName: "ServiceAddressRecipient",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.service_address_recipient",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 25,
      defaultItemName: "RemittanceAddress",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.remittance_address",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 26,
      defaultItemName: "RemittanceAddressRecipient",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.remittance_address_recipient",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 27,
      defaultItemName: "ServiceStartDate",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.service_start_date",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 28,
      defaultItemName: "ServiceEndDate",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.service_end_date",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 29,
      defaultItemName: "PreviousUnpaidBalance",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.previous_unpaid_balance",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 30,
      defaultItemName: "CurrencyCode",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.currency_code",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 31,
      defaultItemName: "PaymentDetails",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.payment_details",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 32,
      defaultItemName: "TotalDiscount",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.invoice.total_discount",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 33,
      defaultItemName: "Items",
      type: "form_setting.prebuilt.type.items",
      description: "form_setting.prebuilt.invoice.items",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 34,
      defaultItemName: "ProductCode",
      type: "form_setting.prebuilt.type.items",
      description: "form_setting.prebuilt.invoice.product_code",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 35,
      defaultItemName: "Date",
      type: "form_setting.prebuilt.type.items",
      description: "form_setting.prebuilt.invoice.date",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 36,
      defaultItemName: "Description",
      type: "form_setting.prebuilt.type.items",
      description: "form_setting.prebuilt.invoice.description",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 37,
      defaultItemName: "Quantity",
      type: "form_setting.prebuilt.type.items",
      description: "form_setting.prebuilt.invoice.quantity",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 38,
      defaultItemName: "Unit",
      type: "form_setting.prebuilt.type.items",
      description: "form_setting.prebuilt.invoice.unit",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 39,
      defaultItemName: "UnitPrice",
      type: "form_setting.prebuilt.type.items",
      description: "form_setting.prebuilt.invoice.unit_price",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 40,
      defaultItemName: "Amount",
      type: "form_setting.prebuilt.type.items",
      description: "form_setting.prebuilt.invoice.amount",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 41,
      defaultItemName: "Tax",
      type: "form_setting.prebuilt.type.items",
      description: "form_setting.prebuilt.invoice.tax",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 42,
      defaultItemName: "TaxRate",
      type: "form_setting.prebuilt.type.items",
      description: "form_setting.prebuilt.invoice.tax_rate",
      checked: false,
      convertedItemName: "",
    }
  ];
  @observable prebuiltReceiptDefault = [
    {
      id: 1,
      defaultItemName: "MerchantName",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.receipt.merchant_name",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 2,
      defaultItemName: "MerchantPhoneNumber",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.receipt.merchant_phone_number",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 3,
      defaultItemName: "MerchantAddress",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.receipt.merchant_address",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 4,
      defaultItemName: "TransactionDate",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.receipt.transaction_date",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 5,
      defaultItemName: "TransactionTime",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.receipt.transaction_time",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 6,
      defaultItemName: "Subtotal",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.receipt.subtotal",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 7,
      defaultItemName: "Total",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.receipt.total",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 8,
      defaultItemName: "TotalTax",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.receipt.total_tax",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 9,
      defaultItemName: "Tip",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.receipt.tip",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 10,
      defaultItemName: "ArrivalDate",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.receipt.arrival_date",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 11,
      defaultItemName: "DepartureDate",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.receipt.departure_date",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 12,
      defaultItemName: "Currency",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.receipt.currency",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 13,
      defaultItemName: "MerchantAliases",
      type: "form_setting.prebuilt.type.header",
      description: "form_setting.prebuilt.receipt.merchant_aliases",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 14,
      defaultItemName: "ProductCode",
      type: "form_setting.prebuilt.type.items",
      description: "form_setting.prebuilt.receipt.product_code",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 15,
      defaultItemName: "Date",
      type: "form_setting.prebuilt.type.items",
      description: "form_setting.prebuilt.receipt.date",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 16,
      defaultItemName: "Description",
      type: "form_setting.prebuilt.type.items",
      description: "form_setting.prebuilt.receipt.description",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 17,
      defaultItemName: "Quantity",
      type: "form_setting.prebuilt.type.items",
      description: "form_setting.prebuilt.receipt.quantity",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 18,
      defaultItemName: "QuantityUnit",
      type: "form_setting.prebuilt.type.items",
      description: "form_setting.prebuilt.receipt.quantity_unit",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 19,
      defaultItemName: "Price",
      type: "form_setting.prebuilt.type.items",
      description: "form_setting.prebuilt.receipt.price",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 20,
      defaultItemName: "TotalPrice",
      type: "form_setting.prebuilt.type.items",
      description: "form_setting.prebuilt.receipt.total_price",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 21,
      defaultItemName: "Category",
      type: "form_setting.prebuilt.type.items",
      description: "form_setting.prebuilt.receipt.category",
      checked: false,
      convertedItemName: "",
    },
    {
      id: 22,
      defaultItemName: "Amount",
      type: "form_setting.prebuilt.type.tax_details",
      description: "form_setting.prebuilt.receipt.amount",
      checked: false,
      convertedItemName: "",
    },
  ];
  @observable claude35Settings = {};
  @observable claude35Setting = {};
  @observable claude35SettingDefault = {
    id: -1,
    settingName: "",
    MERGE_HEADER_AND_DETAIL: false,
    columns: [
      {
        key: "",
        pattern: "",
        type: "-1",
        postJob: "",
      }
    ],
  };

  constructor(props) {
    super(props);
    this.api = new FormSettingApi();
    this.formSetting = JSON.parse(JSON.stringify(this.formSettingDefault));
    this.prebuiltTable = JSON.parse(JSON.stringify(this.prebuiltInvoiceDefault));
    this.claude35Setting = JSON.parse(JSON.stringify(this.claude35SettingDefault));
  }

  // @action 
  // initial = () => {
  //   this.formSetting = this.formSettingDefault;
  //   this.getSortListFormSettings();
  // }

  async getAll(cb = null) {
    let response = await ApiStore.call(this.api, this.api.getAll);
    if (response) {
      runInAction(() => {
        this.fullFormSettings = response.map(el => {
          el.settingBody = JSON.parse(el.settingBody)
          return el
        });
        if (this.fullFormSettings && this.fullFormSettings.length > 0) {
          this.sortListFormSettings = this.fullFormSettings
            .filter(e => e.formEdition == this.formEdition && e.settingName != PREBUILT_MODELS.CLAUDE_3_5)
            .map(({ id, settingName }) => ({ id, settingName }));
        }
        else {
          this.sortListFormSettings = [];
        }
        let claude35 = this.fullFormSettings.find((e) => e.settingName == PREBUILT_MODELS.CLAUDE_3_5);
        if (claude35) {
          this.claude35Settings = claude35;
        }
        cb && cb();
      })
    }
  }

  async getList() {
    let response = await ApiStore.call(this.api, this.api.getList);
    if (response) {
      runInAction(() => {
        this.listFormSettings = response;
      })
    }
  }

  addFormSetting = async (payload) => {
    let response = await ApiStore.call(this.api, this.api.addFormSetting, payload);
    if (response) {
      runInAction(() => {
        this.getAll();
        this.setFormSetting(this.formSettingDefault);
      })
    }
  }

  updateFormSetting = async (payload) => {
    let response = await ApiStore.call(this.api, this.api.updateFormSetting, payload);
    if (response) {
      runInAction(() => {
        this.getAll();
        response.settingBody = JSON.parse(response.settingBody);
        this.formSetting = response;
      })
    }
  }

  @action
  async deleteFormSetting(id) {
    let response = await ApiStore.call(this.api, this.api.deleteFormSetting, id);
    if (response) {
      runInAction(() => {
        this.getAll();
        this.setFormSetting(this.formSettingDefault);
      })
    }
  }

  @action
  setFormSettingBody = (key, value) => {
    this.formSetting.settingBody[key] = value;
    // let formSettingTmp = JSON.parse(JSON.stringify(this.formSetting));
    // formSettingTmp.settingBody[key] = value;
    // this.formSetting = JSON.parse(JSON.stringify(formSettingTmp));
  }

  @action
  addFormSettingBody = (key) => {
    let value = this.prebuiltTable.find(e => e.defaultItemName == key).convertedItemName;
    this.formSetting.settingBody[key] = value;
  }

  @action
  deleteFormSettingBody = (key) => {
    delete this.formSetting.settingBody[key];
  }

  @action
  setFormSetting = (data) => {
    this.formSetting = JSON.parse(JSON.stringify(data));
    if (data.formEdition == CONFIG_FILE_EDITION.PREBUILT && data.settingName != PREBUILT_MODELS.CLAUDE_3_5) {
      let defaultTable = null;
      switch (data.settingName) {
        case PREBUILT_MODELS.INVOICE:
          defaultTable = this.prebuiltInvoiceDefault;
          break;
        case PREBUILT_MODELS.RECEIPT:
          defaultTable = this.prebuiltReceiptDefault;
          break;
      }
      this.prebuiltTable = defaultTable.map(e => {
        if (data.settingBody.hasOwnProperty(e.defaultItemName)) {
          e.checked = true;
          e.convertedItemName = data.settingBody[e.defaultItemName];
        }
        return e;
      });
    }
  }

  @action
  setFormEdition = (data) => {
    this.formEdition = data;
    this.sortListFormSettings = this.fullFormSettings
      .filter(e => e.formEdition == this.formEdition && e.settingName != PREBUILT_MODELS.CLAUDE_3_5)
      .map(({ id, settingName }) => ({ id, settingName }))
      .sort((a, b) => a.id - b.id);
  }

  @action
  changePrebuiltTableColumn = (key, value) => {
    for (let e of this.prebuiltTable) {
      if (e.defaultItemName == key) {
        e.convertedItemName = value;
        break;
      }
    }
  }

  @action
  changeClaude35BasicSetting = (key, value) => {
    this.claude35Setting[key] = value;
  }

  @action
  setClaude35ColumnSetting = (data) => {
    this.claude35Setting = JSON.parse(JSON.stringify(data));
  }

  @action
  changeClaude35KeyColumn = (idx, value) => {
    this.claude35Setting.columns[idx].key = value;
  }

  @action
  changeClaude35PatternColumn = (idx, value) => {
    this.claude35Setting.columns[idx].pattern = value;
  }

  @action
  changeClaude35TypeColumn = (idx, value) => {
    this.claude35Setting.columns[idx].type = value;
  }

  @action
  changeClaude35PostJobColumn = (idx, value) => {
    this.claude35Setting.columns[idx].postJob = value;
  }

  @action
  insertClaude35Column = (idx) => {
    var data = this.claude35Setting;
    var column = {
      key: "",
      pattern: "",
      type: "-1",
      postJob: "",
    };
    data.columns.splice(idx, 0, column);
    this.claude35Setting = JSON.parse(JSON.stringify(data));
  }

  @action
  insertTailClaude35Column = () => {
    var data = this.claude35Setting;
    var column = {
      key: "",
      pattern: "",
      type: "-1",
      postJob: "",
    };
    data.columns.push(column);
    this.claude35Setting = JSON.parse(JSON.stringify(data));
  }

  @action
  deleteClaude35Column = (idx) => {
    var data = this.claude35Setting;
    data.columns.splice(idx, 1);
    this.claude35Setting = JSON.parse(JSON.stringify(data));
  }

  createClaude35AddPayload = (settingName) => {
    let body = JSON.parse(JSON.stringify(this.claude35Settings.settingBody));
    let maxId = Math.max.apply(Math, this.claude35Settings?.settingBody?.settings.map(function (o) { return o.id ? o.id : 0; }));
    let settingId = (maxId !== undefined && maxId !== null && maxId >= 0) ? maxId + 1 : 0;
    let new_setting = JSON.parse(JSON.stringify(this.claude35Setting));
    new_setting.columns = new_setting.columns.filter(e => !this.isBlank(e.key) && !this.isBlank(e.pattern));
    new_setting.id = settingId;
    new_setting.settingName = settingName;
    if (new_setting.MERGE_HEADER_AND_DETAIL === null || new_setting.MERGE_HEADER_AND_DETAIL === undefined) {
      new_setting.MERGE_HEADER_AND_DETAIL = false;
    }
    body.settings.push(new_setting);

    return {
      "id": this.claude35Settings.id,
      "form_edition": this.claude35Settings.formEdition,
      "setting_body": JSON.stringify(body)
    };
  }

  addClaude35Setting = async (payload) => {
    let response = await ApiStore.call(this.api, this.api.updateFormSetting, payload);
    if (response) {
      runInAction(() => {
        this.getAll();
        this.setClaude35ColumnSetting(this.claude35SettingDefault);
      })
    }
  }

  createClaude35UpdatePayload = () => {
    let body = JSON.parse(JSON.stringify(this.claude35Settings.settingBody));
    let new_setting = JSON.parse(JSON.stringify(this.claude35Setting));
    new_setting.columns = new_setting.columns.filter(e => !this.isBlank(e.key) && !this.isBlank(e.pattern));
    if (new_setting.MERGE_HEADER_AND_DETAIL === null || new_setting.MERGE_HEADER_AND_DETAIL === undefined) {
      new_setting.MERGE_HEADER_AND_DETAIL = false;
    }

    for (let i = 0; i < body.settings.length; i++) {
      let id = body.settings[i].id;
      if (id == new_setting.id) {
        body.settings[i] = new_setting;
        break;
      }
    }

    return {
      "id": this.claude35Settings.id,
      "form_edition": this.claude35Settings.formEdition,
      "setting_body": JSON.stringify(body)
    };
  }

  updateClaude35Setting = async (payload) => {
    let response = await ApiStore.call(this.api, this.api.updateFormSetting, payload);
    if (response) {
      runInAction(() => {
        this.getAll();
        response.settingBody = JSON.parse(response.settingBody);
        let setting = response.settingBody.settings.find(e => { return e.id == this.claude35Setting.id; });
        if (setting !== undefined && setting !== null) {
          this.setClaude35ColumnSetting(setting);
        } else {
          this.setClaude35ColumnSetting(this.claude35SettingDefault);
        }
      })
    }
  }

  createClaude35DeletePayload = () => {
    let body = JSON.parse(JSON.stringify(this.claude35Settings.settingBody));
    let delete_id = this.claude35Setting.id;
    body.settings = body.settings
      .filter(e => { return e.id != delete_id; })
      .map((e, idx) => { e.id = idx; return e });

    return {
      "id": this.claude35Settings.id,
      "form_edition": this.claude35Settings.formEdition,
      "setting_body": JSON.stringify(body)
    };
  }

  deleteClaude35Setting = async (payload) => {
    let response = await ApiStore.call(this.api, this.api.updateFormSetting, payload);
    if (response) {
      runInAction(() => {
        this.getAll();
        this.setClaude35ColumnSetting(this.claude35SettingDefault);
      })
    }
  }

  @action
  enablePrebuiltTableColumn = (key) => {
    for (let e of this.prebuiltTable) {
      if (e.defaultItemName == key) {
        e.checked = true;
        break;
      }
    }
  }

  @action
  disablePrebuiltTableColumn = (key) => {
    for (let e of this.prebuiltTable) {
      if (e.defaultItemName == key) {
        e.checked = false;
        break;
      }
    }
  }

  @action
  convertFormSetting = (settingBody) => {
    if (settingBody) {
      settingBody.PAGE_SEG_MODE = !this.isBlank(settingBody.PAGE_SEG_MODE) ? parseInt(settingBody.PAGE_SEG_MODE) : '';
      settingBody.RESCAN_THRESHOLD = !this.isBlank(settingBody.RESCAN_THRESHOLD) ? parseInt(settingBody.RESCAN_THRESHOLD) : '';
      settingBody.PROC_MAX_PAGE = !this.isBlank(settingBody.PROC_MAX_PAGE) ? parseInt(settingBody.PROC_MAX_PAGE) : '';
      settingBody.FILE_IDENFICATION_TYPE = !this.isBlank(settingBody.FILE_IDENFICATION_TYPE) ? parseInt(settingBody.FILE_IDENFICATION_TYPE) : '';
      settingBody.LINE_REMOVAL_THRESHOLD = !this.isBlank(settingBody.LINE_REMOVAL_THRESHOLD) ? parseInt(settingBody.LINE_REMOVAL_THRESHOLD) : '';
      settingBody.LINE_REMOVAL_MULTIPLE_BY_TEXT_HEGHT = !this.isBlank(settingBody.LINE_REMOVAL_MULTIPLE_BY_TEXT_HEGHT) ? parseFloat(settingBody.LINE_REMOVAL_MULTIPLE_BY_TEXT_HEGHT) : '';
      settingBody.HOUGH_THRESHOLD = !this.isBlank(settingBody.HOUGH_THRESHOLD) ? parseInt(settingBody.HOUGH_THRESHOLD) : '';
      settingBody.THRESH_VALUE = !this.isBlank(settingBody.THRESH_VALUE) ? parseInt(settingBody.THRESH_VALUE) : '';
      settingBody.BINTHRESH_ON_LINE_REMOVAL = !this.isBlank(settingBody.BINTHRESH_ON_LINE_REMOVAL) ? parseInt(settingBody.BINTHRESH_ON_LINE_REMOVAL) : '';
      settingBody.BLACK_WHITE_THRESHOLD = !this.isBlank(settingBody.BLACK_WHITE_THRESHOLD) ? parseInt(settingBody.BLACK_WHITE_THRESHOLD) : '';
      settingBody.SHARPEN_VALUE = !this.isBlank(settingBody.SHARPEN_VALUE) ? parseFloat(settingBody.SHARPEN_VALUE) : '';
      settingBody.SHORT_LINE_THRESH_H = !this.isBlank(settingBody.SHORT_LINE_THRESH_H) ? parseInt(settingBody.SHORT_LINE_THRESH_H) : '';
      settingBody.SHORT_LINE_THRESH_V = !this.isBlank(settingBody.SHORT_LINE_THRESH_V) ? parseInt(settingBody.SHORT_LINE_THRESH_V) : '';
      settingBody.THIN_LINE_REMOVAL_THRESHOLD_W = !this.isBlank(settingBody.THIN_LINE_REMOVAL_THRESHOLD_W) ? parseInt(settingBody.THIN_LINE_REMOVAL_THRESHOLD_W) : '';
      settingBody.THIN_LINE_REMOVAL_THRESHOLD_H = !this.isBlank(settingBody.THIN_LINE_REMOVAL_THRESHOLD_H) ? parseInt(settingBody.THIN_LINE_REMOVAL_THRESHOLD_H) : '';
      settingBody.ERODE_THIN_LINE_W = !this.isBlank(settingBody.ERODE_THIN_LINE_W) ? parseInt(settingBody.ERODE_THIN_LINE_W) : '';
      settingBody.ERODE_THIN_LINE_H = !this.isBlank(settingBody.ERODE_THIN_LINE_H) ? parseInt(settingBody.ERODE_THIN_LINE_H) : '';
      settingBody.LINE_EXTENSION_LEN = !this.isBlank(settingBody.LINE_EXTENSION_LEN) ? parseInt(settingBody.LINE_EXTENSION_LEN) : '';
      settingBody.ROUNDED_CORNER_THRESHOLD = !this.isBlank(settingBody.ROUNDED_CORNER_THRESHOLD) ? parseInt(settingBody.ROUNDED_CORNER_THRESHOLD) : '';
      settingBody.ROUNDED_CORNER_PADDING = !this.isBlank(settingBody.ROUNDED_CORNER_PADDING) ? parseInt(settingBody.ROUNDED_CORNER_PADDING) : '';
      settingBody.DOT_SIZE_THRESHOLD = !this.isBlank(settingBody.DOT_SIZE_THRESHOLD) ? parseInt(settingBody.DOT_SIZE_THRESHOLD) : '';
      settingBody.REMOVE_RECTANGLE_MIN_AREA_THRESHOLD = !this.isBlank(settingBody.REMOVE_RECTANGLE_MIN_AREA_THRESHOLD) ? parseInt(settingBody.REMOVE_RECTANGLE_MIN_AREA_THRESHOLD) : '';
      settingBody.REMOVE_RECTANGLE_MAX_AREA_THRESHOLD = !this.isBlank(settingBody.REMOVE_RECTANGLE_MAX_AREA_THRESHOLD) ? parseInt(settingBody.REMOVE_RECTANGLE_MAX_AREA_THRESHOLD) : '';
      settingBody.LINKED_ITEM_THRESHOLD = !this.isBlank(settingBody.LINKED_ITEM_THRESHOLD) ? parseFloat(settingBody.LINKED_ITEM_THRESHOLD) : '';
    }
    return settingBody;
  }

  @action
  clean() {
    if (!this.keepFilterState) {
      console.log("FormSettingStore -> clean -> this.keepFilterState", this.keepFilterState)
      super.clean();
    }
  }

  isBlank = (value) => {
    if (typeof (value) === "string") {
      value = value.trim();
    }
    if (value === null || value === undefined || value === "") {
      return true;
    }
    return false;
  }
}

export default FormSettingStore;