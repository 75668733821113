import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { ContainerBox, Table, ModalFooter } from "components";
import SearchBox from "./SearchBox";
import { CONFIG_FILE_EDITION, PREBUILT_MODELS, TABLE_CODE, ERROR_CODE } from "../../core/utils/constants"
import URL_CONFIG from './../../core/services/config'

import {
  ACCESSOR_DOWNLOAD,
  TABLE_ROW_WIDTH,
  DOCUMENT_STATUS,
  DATE_FORMAT,
  EMPTY_FORMAT_CELL,
  MODAL_ID
} from "../../core/utils/constants";
import { observer, inject } from "mobx-react";
import moment from "moment";
import "./style.scss";
import BaseComponent from "../BaseComponent";

@inject('documentsStore', 'modalStore')
@observer
class Documents extends BaseComponent {
  constructor(props) {
    super(props);
    this.listCheckedItems = {};
    this.fullDataOfListCheckedItems = {};
    this.currentDocumentType = null;
    this.state = {
      configFileEdition: null,
      configFileCode: null,
      formGroupId: null,
      documentId: null,
      checkAllDisabled: true,
      checkAllList: {},
    };
  }

  cleanCheckedItem = () => {
    this.listCheckedItems = {};
    this.fullDataOfListCheckedItems = {};
    this.currentDocumentType = null;
    this.setState({
      configFileEdition: null,
      configFileCode: null,
      formGroupId: null,
      documentId: null,
      checkAllDisabled: true,
      checkAllList: {},
    });
  };

  /**
   * onEdit
   * go edit screen
   * @param {int} id: id of document
   *
   * @returns redirect to edit screen
   */
  onEdit = (id) => {
    this.props.history.push("/documents/edit/" + id);
  };

  isAccessableItem = (status) => {
    return !(status === DOCUMENT_STATUS.PROCESSING);
  };

  isEditable = (status) => {
    return !(
      status === DOCUMENT_STATUS.PROCESSING || status === DOCUMENT_STATUS.ERROR
    );
  };

  getDocumentById = (id) => {
    let doc = null;
    if (this.props.documentsStore?.documents) {
      Object.keys(this.fullDataOfListCheckedItems).forEach((key) => {
        if (id === this.fullDataOfListCheckedItems[key].id) {
          doc = this.fullDataOfListCheckedItems[key];
        }
      });
    }
    return doc;
  };

  isProcessingItem(element) {
    return element.status === DOCUMENT_STATUS.PROCESSING;
  }

  isSelectedItem = (id) => {
    let isChecked = false;
    Object.keys(this.listCheckedItems).forEach((key) => {
      if (id === parseInt(this.listCheckedItems[key])) {
        isChecked = true;
      }
    })
    return isChecked;
  };

  isSelectedOrProcessingItem(element) {
    let isSelectedOrProcessing = this.isSelectedItem(element.id);
    if (!isSelectedOrProcessing) {
      if (parseInt(element.status) === 0) {
        isSelectedOrProcessing = true;
      }
    }
    return isSelectedOrProcessing;
  }

  isCheckAll = (page) => {
    let isCheckAll = false;
    if (this.state.checkAllList) {
      Object.keys(this.state.checkAllList).forEach((key) => {
        if (page === parseInt(key) && this.state.checkAllList[key]) {
          isCheckAll = true;
        }
      })
    }
    return isCheckAll;
  }

  checkAllActionHandler = (e) => {
    if (e.target.checked) {
      this.addItemsToList();
    } else {
      this.removeItemsFromList();
    }

    let checkAllList = this.state.checkAllList;
    checkAllList[e.target.id] = e.target.checked;
    this.setState({
      checkAllList: checkAllList
    });
  }

  checkAllDisabledHandler = () => {
    // get current state --> set to true if false and versa
    const { t } = this.props;
    let documents = this.props.documentsStore?.documents;
    let shouldEnable = true;
    if (documents && documents.length > 0) {
      // Get configFileCode
      let configFileCode = this.state.configFileCode;
      if (!configFileCode && documents[0]) {
        configFileCode = documents[0].configFileCode;
      }
      // Get formGroupId
      let formGroupId = this.state.formGroupId;
      if (!formGroupId && documents[0]) {
        formGroupId = documents[0].formGroupId;
      }
      // Get configFileEdition
      let configFileEdition = this.state.configFileEdition;
      if (!configFileEdition && documents[0]) {
        configFileEdition = documents[0].configFileEdition;
      }

      // - Normal: There are 2 file edition configs: [Item] & [Manual]
      // - However: There are some special cases where data is error.
      //            Then value of fields [configFileEdition], [configFileCode] are null.
      // - The conditions to select records are as follows:
      //  . Only select records with the same file edition config
      //  . If config file edition = [Item] then only enable records of the same group
      //  . If config file edition = [Manual] or others then only enable records of the same definition
      let itemDocuments = documents.filter(d =>
        d.configFileEdition === CONFIG_FILE_EDITION.ITEM || d.configFileEdition === CONFIG_FILE_EDITION.RULE);

      // Include [Item]
      if (itemDocuments.length > 0) {
        // Only [Item]
        if (itemDocuments.length == documents.length) {
          documents.forEach((element) => {
            if (element.formGroupId !== formGroupId) {
              shouldEnable = false;
            }
          });
        }
        // [Item] & others
        else {
          shouldEnable = false;
        }
      }
      // Not include [Item]
      else {
        documents.forEach((element) => {
          if (element.configFileEdition !== configFileEdition ||
            element.configFileCode !== configFileCode) {
            shouldEnable = false;
          }
        });
      }

      let allItemsAreProcessing = true;
      documents.forEach((element) => {
        if (!this.isProcessingItem(element)) {
          allItemsAreProcessing = false;
        }
      });

      if (allItemsAreProcessing) {
        shouldEnable = false;
      }

      if (shouldEnable === this.state.checkAllDisabled) {
        this.setState({ checkAllDisabled: !shouldEnable });
      }

      let elements = document.getElementsByClassName("cb-check-all");
      if (elements && elements[0]) {
        if (shouldEnable) {
          elements[0].removeAttribute("title");
        } else {
          elements[0].setAttribute("title", t("download.select_data_must_same_type"));
        }
      }
    }
  }

  checkAllHandler = () => {
    let documents = this.props.documentsStore?.documents;
    let shouldCheck = true;
    let allItemsAreProcessing = true;
    if (documents && documents.length > 0) {
      // - Normal: There are 2 file edition configs: [Item] & [Manual]
      // - However: There are some special cases where data is error.
      //            Then value of fields [configFileEdition], [configFileCode] are null.
      // - The conditions to select records are as follows:
      //  . Only select records with the same file edition config
      //  . If config file edition = [Item] then only enable records of the same group
      //  . If config file edition = [Manual] or others then only enable records of the same definition
      let itemDocuments = documents.filter(d =>
        d.configFileEdition === CONFIG_FILE_EDITION.ITEM || d.configFileEdition === CONFIG_FILE_EDITION.RULE);

      // Include [Item]
      if (itemDocuments.length > 0) {
        // Only [Item]
        if (itemDocuments.length === documents.length) {
          itemDocuments.forEach((element) => {
            if (!this.isSelectedOrProcessingItem(element) || element.formGroupId !== this.state.formGroupId) {
              shouldCheck = false;
            }
          });
        }
        // [Item] & others
        else {
          shouldCheck = false;
        }
      }
      // Not include [Item]
      else {
        documents.forEach((element) => {
          if (!this.isSelectedOrProcessingItem(element) ||
            element.configFileEdition !== this.state.configFileEdition ||
            element.configFileCode !== this.state.configFileCode) {
            shouldCheck = false;
          }
        });
      }

      documents.forEach((element) => {
        if (!this.isProcessingItem(element)) {
          allItemsAreProcessing = false;
        }
      });
    }

    if (allItemsAreProcessing) {
      shouldCheck = false;
    }

    let paging = this.props.documentsStore?.paging;
    if (paging) {
      let currentCheckedStatus = this.state.checkAllList[paging.page];
      if (shouldCheck !== currentCheckedStatus) {
        let checkAllList = this.state.checkAllList;
        checkAllList[paging.page] = shouldCheck;
        this.setState({
          checkAllList: checkAllList
        });
      }
    }
  }

  // table height
  /**
   * columns
   *
   * @returns column objects for table
   */
  columns = () => {
    const { t } = this.props;
    return [
      {
        id: "selection",
        Header: (row) => {
          let pageIndex = row.initialState?.initialTableState?.pageIndex;
          if (pageIndex || pageIndex === 0) {
            pageIndex += 1;
          }
          return <input id={pageIndex}
            className="cb-check-all"
            title={t("download.select_data_must_same_type")}
            type="checkbox"
            disabled={this.state.checkAllDisabled}
            checked={this.isCheckAll(pageIndex)}
            onChange={this.checkAllActionHandler} />
        },
        Cell: ({ row }) => {
          let isChecked = this.isSelectedItem(row.original.id);
          return (
            this.isAccessableItem(row?.original?.status) && (
              <div>
                <input
                  id={row.original.idx}
                  type="checkbox"
                  value={JSON.stringify(row?.original)}
                  onChange={this.handleRowSelected}
                  checked={isChecked}
                  disabled={this.isDisableRow(row)}
                />
              </div>
            )
          );
        },
        width: "3%",
      },
      {
        Header: t("download.no"),
        disableSortBy: true,
        width: "4%",
        accessor: ACCESSOR_DOWNLOAD.INDEX,
      },
      {
        Header: t("download.file_name"),
        accessor: ACCESSOR_DOWNLOAD.FILE_NAME,
        width: "16%",
      },
      {
        Header: t("download.pagenumber"),
        accessor: ACCESSOR_DOWNLOAD.PAGE_NO,
        width: "9%",
        Cell: ({ row }) =>
          row?.original?.pageNo
            ? row?.original?.pageNo
            : EMPTY_FORMAT_CELL,
      },
      {
        Header: t("download.formGroup"),
        accessor: ACCESSOR_DOWNLOAD.FORM_GROUP,
        width: "14%",
        Cell: ({ row }) => {
          if (row?.original?.configFileEdition == CONFIG_FILE_EDITION.PREBUILT) {
            return t("download.prebuilt");
          } else {
            return row?.original?.formGroupName
              ? row?.original?.formGroupName
              : EMPTY_FORMAT_CELL;
          }
        }
      },
      {
        Header: t("download.definition_file"),
        accessor: ACCESSOR_DOWNLOAD.CONFIG_NAME,
        width: TABLE_ROW_WIDTH.NORMAL_15_PERCENT,
        Cell: ({ row }) => {
          if (row?.original?.configFileEdition == CONFIG_FILE_EDITION.PREBUILT) {
            switch (row?.original?.configFileName) {
              case PREBUILT_MODELS.INVOICE:
                return t("download.prebuilt_invoice");
              case PREBUILT_MODELS.RECEIPT:
                return t("download.prebuilt_receipt");
              case PREBUILT_MODELS.CLAUDE_3_5:
                return t("download.prebuilt_claude_3_5");
              default:
                return EMPTY_FORMAT_CELL;
            }
          } else {
            return row?.original?.configFileName
              ? row?.original?.configFileName
              : EMPTY_FORMAT_CELL;
          }
        }
      },
      {
        Header: t("download.registered_date"),
        accessor: ACCESSOR_DOWNLOAD.CREATED_AT,
        Cell: ({ row }) =>
          row?.original?.createdAt
            ? moment(row?.original?.createdAt).format(DATE_FORMAT.full_time)
            : EMPTY_FORMAT_CELL,
        width: "11%",
      },
      {
        Header: t("download.update_date_and_time"),
        accessor: ACCESSOR_DOWNLOAD.UPDATED_AT,
        Cell: ({ row }) =>
          row?.original?.updatedAt
            ? moment(row?.original?.updatedAt).format(DATE_FORMAT.full_time)
            : EMPTY_FORMAT_CELL,
        width: "11%",
      },
      {
        Header: t("download.status"),
        id: ACCESSOR_DOWNLOAD.STATUS,
        canSort: true,
        Cell: ({ row }) => {
          if (row?.original?.status == DOCUMENT_STATUS.ERROR) {
            return <div className="color-theme cursor-p" onClick={() => this.openErrorCodeModal(row)}>
              {t("download.status_" + row?.original?.status)}
            </div>;
          } else {
            return t("download.status_" + row?.original?.status);
          }
        },
        width: "13%",
      },
      {
        Header: t("download.action"),
        id: ACCESSOR_DOWNLOAD.ACTION,
        className: 'download-action',
        Cell: ({ row }) => {
          return (

            this.isEditable(row?.original?.status) && (
              <button
                className="btn btn-primary document-btn-edit"
                type="button"
                onClick={() => this.onEdit(row?.original?.id)}
              >
                {t("download.edit")}
              </button>
            )
          );
        },
        width: 80,
      },
    ];
  };

  /**
   * componentWillMount
   * get list documents from BE
   *
   */
  componentWillMount = () => {
    this.props.documentsStore.clean();
    this.props.documentsStore.getDocuments();
  };

  executeOnDidUpdate = () => {
    const { t } = this.props;
    let elements = document.getElementsByClassName("btn-delete-document");
    if (elements && elements[0]) {
      if (Object.keys(this.listCheckedItems).length !== 0) {
        elements[0].removeAttribute("title");
      } else {
        elements[0].setAttribute("title", t("download.select_to_remove"));
      }
    }

    let elements2 = document.getElementsByClassName("btn-download-document");
    if (elements2 && elements2[0]) {
      if (Object.keys(this.listCheckedItems).length !== 0) {
        elements2[0].removeAttribute("title");
      } else {
        elements2[0].setAttribute("title", t("download.select_to_download"));
      }
    }

    this.checkAllDisabledHandler();
    this.checkAllHandler();
  }

  removeItemsFromList() {
    let documents = this.props.documentsStore?.documents;
    if (documents) {
      documents.forEach((element) => {
        this.removeItemFromList(element);
      });
    }
  }

  removeItemFromList(element) {
    delete this.listCheckedItems[element.idx];
    delete this.fullDataOfListCheckedItems[element.idx];
    this.rowHandler(false, element);
  }

  addItemsToList = () => {
    let documents = this.props.documentsStore?.documents;
    if (documents) {
      documents.forEach((element) => {
        if (element && parseInt(element.status) !== 0) {
          this.addItemToList(element);
        }
      });
    }
  }

  addItemToList = (element) => {
    this.listCheckedItems[element.idx] = element.id;
    this.fullDataOfListCheckedItems[element.idx] = element;
    this.rowHandler(true, element);
  }

  rowHandler = (isChecked, element) => {
    if (Object.keys(this.listCheckedItems).length >= 1) {
      isChecked = true;
    }
    if (isChecked) {
      this.currentDocumentType = element.configFileEdition;
      this.setState({
        configFileEdition: element.configFileEdition,
        configFileCode: element.configFileCode,
        formGroupId: element.formGroupId,
        documentId: element.id,
      });
    } else {
      this.currentDocumentType = null;
      this.setState({
        configFileEdition: null,
        configFileCode: null,
        formGroupId: null,
        documentId: null,
      });
    }
  }

  /**
   * handleChange
   * handle select checkbox events
   * 1.update list selected items - give it to upload function later
   * 2.update disable value on list documents - for update ui
   */
  handleRowSelected = (e) => {
    let element = e.target.value;
    if (element) {
      element = JSON.parse(element);
      if (e.target.checked) {
        this.addItemToList(element);
      } else {
        this.removeItemFromList(element);
      }
    }
  };

  /**
   * handleDownload
   * handle download actions
   *
   */
  handleDownload = (data) => {
    // check if selected has error
    let shouldDownload = true;
    if (this.listCheckedItems) {
      Object.keys(this.listCheckedItems).forEach((key) => {
        let doc = this.getDocumentById(this.listCheckedItems[key]);
        if (doc && doc.status === DOCUMENT_STATUS.ERROR) {
          shouldDownload = false;
        }
      });
    }
    if (shouldDownload) {
      this.props.documentsStore.downloadDocuments(
        this.listCheckedItems,
        this.currentDocumentType,
        this.cleanCheckedItem()
      );
    } else {
      this.openModalPreventDownload();
    }
  };

  /**
   * handleDownloadReadingResults
   *
   */
  handleDownloadReadingResults = () => {
    if (this.listCheckedItems && Object.keys(this.listCheckedItems).length > 0) {
      let key = Object.keys(this.listCheckedItems)[0];
      let documentId = this.listCheckedItems[key];
      this.props.documentsStore.downloadReadingResults(
        documentId,
        this.cleanCheckedItem()
      );
    }
  };

  // Close modal
  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hideAll();
  };

  openModalPreventDownload = () => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.CONFIRM,
      isOpen: true,
      header: t("download.cant_download_warning"),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{t("download.cant_download_document")}</span>
          </div>
          <ModalFooter
            key={"modal-footer"}
            saveButtonText={t("download.cant_download_close")}
            onConfirm={() => {
              this.closeModal();
            }}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: "small",
    });
  };

  /**
   * openRemoveComfirmationModal
   * remove document
   * 
   * @param {Object} data: data
   * 
   * @returns  modal
   */
  openRemoveComfirmationModal = (data) => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.REMOVE,
      isOpen: true,
      header: t('document.remove_confirm'),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{t('document.remove_document')}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t('document.remove_yes')}
            onConfirm={() => {
              this.props.documentsStore.deleteDocuments(this.listCheckedItems, this.cleanCheckedItem());
              this.closeModal();
            }
            }
            cancelButtonText={t('document.remove_no')}
            onCancel={this.closeModal}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'small'
    });
  };

  /**
   * openErrorCodeModal
   * show error cause
   * 
   * @param {Object} row: row
   * 
   * @returns  modal
   */
  openErrorCodeModal = (row) => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.ERROR_CODE,
      isOpen: true,
      header: t('document.error_code'),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{this.getErrorText(row?.original?.errorCode)}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t('document.error_code.close')}
            onConfirm={() => { this.closeModal(); }}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'small'
    });
  };

  getErrorText = (cause) => {
    const { t } = this.props;
    switch (cause) {
      case ERROR_CODE.SORTING:
        return t('document.error_code.sorting');
      case ERROR_CODE.PDF_CONVERTION:
        return t('document.error_code.pdf_convertion');
      case ERROR_CODE.TIMEOUT:
        return t('document.error_code.timeout');
      case ERROR_CODE.CSV_LOADING:
        return t('document.error_code.csv_loading');
      default:
        return t('document.error_code.unknown');
    }
  }
  /**
   * handleUpdate
   * update screen follow search filter
   *
   */
  handleUpdate = () => {
    this.cleanCheckedItem();
    this.props.documentsStore.updatePagingFiler({ page: 1 });
    this.props.documentsStore.applySearchBoxFiler();
    this.props.documentsStore.getDocuments();
  };

  /**
   * Disable row in document table
   *
   * - Normal: There are 2 file edition configs: [Item] & [Manual]
   * - However: There are some special cases where data is error.
   *            Then value of fields [configFileEdition], [configFileCode] are null.
   * - The conditions to select records are as follows:
   *  . Only select records with the same file edition config
   *  . If config file edition = [Item] then only enable records of the same group
   *  . If config file edition = [Manual] or others then only enable records of the same definition
  */
  isDisableRow = (row) => {
    if (!row || !(row.constructor === Object && Object.keys(row).length > 0)) {
      return false;
    }

    // Only disable record when selected (this.state.documentId != null)
    if (this.state.documentId != null) {
      if (this.state.configFileEdition !== row.original.configFileEdition) {
        return true;
      }

      if (row.original.configFileEdition === CONFIG_FILE_EDITION.ITEM || row.original.configFileEdition === CONFIG_FILE_EDITION.RULE) {
        if (this.state.formGroupId === row.original.formGroupId) {
          return false;
        }
      }
      else if (this.state.configFileCode === row.original.configFileCode) {
        return false;
      }

      return true;
    }

    return false;
  };

  isDisableBtnDownReadingResults = () => {
    let selectedJobId = null;
    if (Object.keys(this.fullDataOfListCheckedItems).length === 0) {
      return true;
    }

    for (const [key, value] of Object.entries(this.fullDataOfListCheckedItems)) {
      // Only download for [debug_data_url] not null
      if (!value["debugDataUrl"]) {
        return true;
      }

      // Only download 1 file: 1 page or multi pages
      if (!selectedJobId) {
        selectedJobId = value["jobId"]
      }
      if (value["jobId"] !== selectedJobId) {
        return true;
      }
    }

    return false;
  }

  renderSearchElement = () => {
    const { t } = this.props;

    return (
      <button
        className="download-btn-style btn btn-primary mb-3"
        type="button"
        onClick={this.handleUpdate}
      >
        {t("download.btn_update")}
      </button>
    );
  };

  onFetch = (tableData) => {
    this.cleanCheckedItem();
    this.props.documentsStore.updatePagingFiler(tableData);
    this.props.documentsStore.getDocuments();
  };

  /**
   * render
   * render download main content
   *
   */
  render() {
    const { t } = this.props;
    let numOfCheckedItems = Object.keys(this.listCheckedItems).length;
    let customSelectedBox = [10, 50, 100, 500, 1000];
    let role = localStorage.getItem("role");
    return (
      <div className="screen-document">
        <ContainerBox headerTitle={t("download.csv_download")} className="search-container">
          <div className="box-body box-profile">
            <SearchBox savedData={this.props.location.state?.savedSearchState} />
          </div>
        </ContainerBox>
        <ContainerBox type="basic" className="document-container table-container">
          <div className="box-body document-item-info">
            {URL_CONFIG.is_connect_to_azure_app_insight === true && (role == 0 || role == 2) &&
              <button
                title={t("download.select_to_download")}
                disabled={this.isDisableBtnDownReadingResults() === true}
                className="download-btn-style btn btn-primary f-r mb-3 btn-download-document"
                type="button"
                onClick={this.handleDownloadReadingResults}
              >
                {t("download.download_reading_results")}
              </button>
            }
            <button
              title={t("download.select_to_download")}
              disabled={Object.keys(this.listCheckedItems).length === 0}
              className="download-btn-style btn btn-primary f-r mb-3 btn-download-document"
              type="button"
              onClick={this.handleDownload}
            >
              {t("download.download")}
            </button>
            <button
              title={t("download.select_to_remove")}
              disabled={Object.keys(this.listCheckedItems).length === 0}
              className="download-btn-style btn btn-danger f-r mb-3 btn-delete-document"
              type="button"
              onClick={this.openRemoveComfirmationModal}
            >
              {t("document.remove")}
            </button>
            <label className="f-r lbl-selected-item">{t("download.selected_item")}{numOfCheckedItems}</label>
            <Table
              tblCode={TABLE_CODE.DOCUMENT}
              columns={this.columns()}
              data={this.props.documentsStore?.documents}
              disableFieldValue={this.state}
              disableField={this.state}
              t={t}
              element={this.renderSearchElement()}
              customSelectedBox={customSelectedBox}
              disablePaging={false}
              onFetch={this.onFetch}
              enableServerSidePaging={true}
              initialTableState={this.props.documentsStore?.paging}
            />
          </div>
        </ContainerBox>
      </div>
    );
  }
}
export default withRouter(withTranslation()(Documents));
