import { action, observable, runInAction, configure } from "mobx";
import { DocumentsDownloadAPI, CommonApi } from "apis";
import ApiStore from "./ApiStore";
import { DATE_FORMAT, CONFIG_FILE_EDITION } from "../core/utils/constants";
import moment from "moment";
import BaseStore from "./BaseStore";
import URL_CONFIG from '../core/services/config';

configure({ enforceActions: "observed" });
class DocumentsDownloadStore extends BaseStore {
  @observable documents = []; //list all documents
  @observable listAllDefinitions = []; //list all defineition of documents
  @observable listAllDefinitionsCache = []; //list all defineition of documents in cache
  @observable tempFilterData = {};
  @observable keepFilterState = false;
  @observable listGroupDefinitions = [];
  @observable selectedDefinitionId = -1;
  @observable selectedTeamId = null;
  @observable selectedTemplateSortingId = -1;

  // @observable uploadFilesList = [];
  defaultDefinitionName = "";
  //   savedSearchState = {};

  constructor(props) {
    super(props);
    this.api = new DocumentsDownloadAPI();
    this.commonApi = new CommonApi();
  }

  @action initial = () => { }

  /**
   * getDefinitations
   * get the list of defination of documents
   *
   * @returns  boolean
   */
  @action
  async getDefinitations() {
    let tempDefns = await ApiStore.newCall(
      this.commonApi,
      this.commonApi.getDefinitations
    );
    if (tempDefns) {
      runInAction(() => {
        if (!URL_CONFIG.is_connect_to_azure_app_insight) {
          tempDefns = tempDefns.filter(e => e.edition != CONFIG_FILE_EDITION.PREBUILT);
        }
        this.listAllDefinitions = tempDefns;
        this.listAllDefinitionsCache = this.listAllDefinitions;
        if (this.listAllDefinitions && this.listAllDefinitions[0]) {
          this.defaultDefinitionName = this.listAllDefinitions[0].configName;
          this.selectedDefinitionId = this.listAllDefinitions[0].id;
        }
      });
    }
  }

  @action
  async getDefinitationsInTeam() {
    let tempDefns = await ApiStore.newCall(
      this.commonApi,
      this.commonApi.getDefinitationsInTeam
    );
    if (tempDefns) {
      runInAction(() => {
        if (!URL_CONFIG.is_connect_to_azure_app_insight) {
          tempDefns = tempDefns.filter(e => e.edition != CONFIG_FILE_EDITION.PREBUILT);
        }
        this.listAllDefinitions = tempDefns;
        this.listAllDefinitionsCache = this.listAllDefinitions;
        if (this.listAllDefinitions && this.listAllDefinitions[0]) {
          this.defaultDefinitionName = this.listAllDefinitions[0].configName;
          this.selectedDefinitionId = this.listAllDefinitions[0].id;
        }
      });
    }
  }

  @action
  resetConfigFileId() {
    this.tempFilterData.configFileId = "";
  }

  @action
  getDefinitationsByGroup(formGroupId) {
    if (!formGroupId || formGroupId === "null") {
      this.listAllDefinitions = this.listAllDefinitionsCache;
    }
    else {
      this.listAllDefinitions = this.listAllDefinitionsCache.filter(x => x.formGroupId == formGroupId);
    }
    return this.listAllDefinitions;
  }

  @action
  async getAllDefinitationsGroup() {
    let groups = await ApiStore.newCall(
      this.commonApi,
      this.commonApi.getDefinitationsGroup
    );
    if (groups) {
      runInAction(() => {
        if (groups) {
          if (!URL_CONFIG.is_connect_to_azure_app_insight) {
            groups = groups.filter(e => e.edition != CONFIG_FILE_EDITION.PREBUILT);
          }
          this.listGroupDefinitions = groups;
        }
      });
    }
  }

  @action
  async getAllDefinitationsGroupInTeam() {
    let groups = await ApiStore.newCall(
      this.commonApi,
      this.commonApi.getDefinitationsGroupInTeam
    );
    if (groups) {
      runInAction(() => {
        if (groups) {
          if (!URL_CONFIG.is_connect_to_azure_app_insight) {
            groups = groups.filter(e => e.edition != CONFIG_FILE_EDITION.PREBUILT);
          }
          this.listGroupDefinitions = groups;
        }
      });
    }
  }

  /**
   * getDocuments
   * get all document wihtou paging for tables
   *
   * @param {Object} payload : the filter object to query list documents
   * @returns  Null update this.documents and re-render the view
   */
  async getDocuments() {
    const { size, page, sortDir, sortKey } = this.paging;
    let payload = {
      ...this.dataTransformer(this.savedSearchState?.filterData),
      size,
      page,
      sortDir,
      sortKey,
    };
    let response = await ApiStore.call(
      this.api,
      this.api.getDocuments,
      payload
    );
    this.setTempSearchBoxFiler(this.savedSearchState?.filterData);
    if (response) {
      runInAction(() => {
        this.updatePagingFiler(response.paging);
        this.documents = this.documentTransformer(response.elements);
        // save current documents
        localStorage.setItem("documents", JSON.stringify(this.documents));
        if (this.paging?.totalRecord && this.paging?.totalRecord !== 0) {
          if (this.paging?.page > 1 && (!response.elements || response.elements.length === 0)) {
            this.updatePagingFiler({ page: this.paging.page - 1 });
            this.getDocuments();
          }
        }
        this.setKeepFilterState(false);
      });
    }
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  /**
   * downloadDocuments
   * download docuements
   *
   * @param {Array} listCheckedItems  : list target file to download
   * @returns  boolean
   */
  @action
  async downloadDocuments(listCheckedItems, cb = null) {
    let response = await ApiStore.call(this.api, this.api.downloadDocuments, {
      listJobId: Object.values(listCheckedItems)?.join(","),
    });
    if (response) {
      let fileName = "report_default";
      if (response?.headers.get("content-disposition")) {
        let disposition = response?.headers.get("content-disposition");
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, "");
        }
      }

      let allData;
      const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
      if (isIE) {
        var dataFile = [];
        await response.arrayBuffer().then(data => dataFile = data);
        allData = new Uint8Array(dataFile);
      } else {
        const reader = response.body.getReader();
        let chunks = [];
        let dataLength = 0;
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          dataLength += value.length;
          chunks.push(value);
        }

        allData = new Uint8Array(dataLength);
        let pos = 0;
        chunks.forEach(element => {
          allData.set(element, pos);
          pos += element.length;
        });
      }

      this.createAndDownloadBlobFile(allData.buffer, fileName);
      this.setTempSearchBoxFiler(this.savedSearchState?.filterData);
      this.getDocuments();
      cb && cb();
    }
  }

  createAndDownloadBlobFile(body, fileName) {
    const blob = new Blob([body]);
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  @action
  updateSearchBoxCollapse(value) {
    this.savedSearchState = {
      ...this.savedSearchState,
      isCollapseOut: value,
    };
  }

  @action
  dataTransformer(data) {
    let transformedData = { ...data };
    if (transformedData?.fromTime) {
      transformedData.fromTime = moment(transformedData.fromTime).format(DATE_FORMAT.time_stamp);
    }
    if (transformedData?.toTime) {
      transformedData.toTime = moment(transformedData.toTime).format(DATE_FORMAT.time_stamp);
    }
    return transformedData;
  }

  documentTransformer(document) {
    if (document) {
      let idx = (this.paging.page - 1) * this.paging.size + 1;
      document = document.map((element) => {
        element.idx = idx++;
        return element;
      });
    }
    return document;
  }

  @action
  applySearchBoxFiler() {
    this.savedSearchState = {
      ...this.savedSearchState,
      filterData: this.tempFilterData,
    };
  }

  @action
  updateTempSearchBoxFiler(value = null) {
    runInAction(() => {
      this.tempFilterData = { ...this.tempFilterData, ...value };
    });
  }

  @action
  setTempSearchBoxFiler(value = null) {
    runInAction(() => {
      this.tempFilterData = { ...value };
    });
  }

  @action
  setKeepFilterState = (value) => {
    this.keepFilterState = value;
  }

  @action
  clean() {
    if (!this.keepFilterState) {
      super.clean();
      this.savedSearchState = {};
    }
  }

  @action
  resetUserId() {
    this.tempFilterData.userId = "";
  }
}

export default DocumentsDownloadStore;
