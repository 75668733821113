import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { ContainerBox, Table, ModalFooter, ReactPaging } from "components";
import { observer, inject } from "mobx-react";
import CONFIG from "../../../core/services/config";
import { Helper } from "../../../core/utils";
import { PDFDocument, rgb } from 'pdf-lib';
import { SYSTEM_PATH, TABLE_ROW_WIDTH, MODAL_ID, MODAL_SIZE, MIN_ZOOM_LEVEL, ZOOM_STEP, DEFAULT_AUTO_SCALE, CONFIG_FILE_EDITION, DOCUMENT_STATUS, EMPTY_FORMAT_CELL, PREBUILT_MODELS } from "../../../core/utils/constants";
import MyPdfViewer from "../../../components/MyPdfViewer/MyPdfViewer";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "./style.scss";
import ResizablePanels from "resizable-panels-react";
import { Browser } from "core/utils";

@inject("documentsStore", "modalStore", "commonStore")
@observer
class ScreenEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      defaultIndex: 0,
      isDisplayHeader: null,
      itemNameAdd: '',
      rotate: null
    };
    this.viewer = React.createRef();

    /*
    const sessionId = Browser.getSavedInfo("sessionId");
    const documentsList = Browser.getSavedInfo("documents");
    if (sessionId) {
      if (documentsList) {;}
      else {
        this.props.documentsStore.getDocuments();
      }
    }
    */
  }

  verifyAtLeastOneItem = false;
  verifiedList = [];
  pdfViewerWidth = null;
  pdfViewerHeight = null;
  highlightDiv = null;
  userScale = null;

  onChangeItemNameNew = (value) => {
    document.getElementById("required_msg").textContent = '';
    this.setState({
      itemNameAdd: value.trim()
    });
  }
  handleBackButton = () => {
    if (this.verifyAtLeastOneItem) {
      this.openCancelModal();
    }
    else {
      this.props.history.push(this.documentTransferData());
    }
  };

  documentTransferData = () => {
    const location = {
      pathname: SYSTEM_PATH.DOCUMENT,
      // state: { savedSearchState: documentsStore?.savedSearchState }
    };
    return location;
  };

  // modifyPdf = async () => {
  //   const { documentsStore, documentsStore: { pdfBytes, highlights, documentWidth, documentHeight, highlightedItem, currentPage } } = this.props;
  //   if (documentsStore.currentDocument?.inputFilePath && pdfBytes && pdfBytes?.byteLength) {
  //     const pdfDoc = await PDFDocument.load(pdfBytes);
  //     const pages = pdfDoc.getPages();
  //     const firstPage = pages[currentPage - 1];
  //     const { width } = firstPage.getSize();
  //     const ratio = Math.abs(width) / documentWidth;
  //     const wrapper = document.getElementsByClassName("my-pdf-viewer-wrapper")[0];
  //     highlights.forEach(e => {
  //       if (e.id === highlightedItem) {

  //         // draw highlight rectangle
  //         firstPage.drawRectangle({
  //           x: e.x * ratio,
  //           y: (documentHeight - e.y - e.height) * ratio,
  //           width: e.width * ratio,
  //           height: e.height * ratio,
  //           color: rgb(0.2, 0.6, 0.86),
  //           opacity: 0.5
  //           // borderColor: rgb(0.95, 0.1, 0.1),
  //           // borderWidth: 2
  //         })
  //         let zoomLevel = DEFAULT_AUTO_SCALE;

  //         // if highlighted item bigger than viewer we reduce zoom level
  //         if (this.state.userScale) {
  //           zoomLevel = this.state.userScale;
  //         } else {
  //           while (zoomLevel < MIN_ZOOM_LEVEL || wrapper.clientWidth < e.width * ratio * zoomLevel || wrapper.clientHeight < e.height * ratio * zoomLevel) {
  //             zoomLevel -= ZOOM_STEP;
  //           }
  //         }

  //         // this margin keep highlight item at center of pdf viewer
  //         const marginTop = (wrapper.clientHeight - e.height * ratio * zoomLevel) / 2;
  //         const marginLeft = (wrapper.clientWidth - e.width * ratio * zoomLevel) / 2;
  //         /* [Cloud-261] Temporarily remove the zoom and focus handles to reduce processing time
  //           this.viewer.current.onSetZoom(zoomLevel);
  //         */
  //         this.focusDocumentToPosition((e.x * ratio * zoomLevel) - marginLeft, (e.y * ratio * zoomLevel) - marginTop);
  //       }
  //     });
  //     // const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
  //     const pdfDataUri = await pdfDoc.save();
  //     this.viewer.current.onUpdateContent(pdfDataUri);
  //   }
  // }

  hideHighLightElm = () => {
    const d = document.getElementById("highlight-div");
    if (d) {
      d.style.display = "none";
    }
  }

  drawHighLightElm = (userScale) => {
    const { documentsStore, documentsStore: { highlights, documentWidth, documentHeight, highlightedItem } } = this.props;
    if (documentsStore.currentDocument?.inputFilePath && highlights && highlights.length > 0) {
      const wrapper = document.getElementsByClassName("my-pdf-viewer-wrapper")[0];
      // Note: Default scale of pdf viewer is 1
      let zoomLevel = userScale || 1;
      let ratio = this.pdfViewerWidth / documentWidth;

      highlights.every(e => {
        if (e.id === highlightedItem) {
          const d = document.getElementById("highlight-div");
          if (d) {
            // *** Draw highlight rectangle
            let left = e.x * ratio * zoomLevel;
            let top = e.y * ratio * zoomLevel;
            let width = e.width * ratio * zoomLevel;
            let height = e.height * ratio * zoomLevel;

            if (this.state.rotate === 90) {
              top = e.x * ratio * zoomLevel;
              left = (documentHeight - e.y - e.height) * ratio * zoomLevel;
              height = e.width * ratio * zoomLevel;
              width = e.height * ratio * zoomLevel;
            }
            else if (this.state.rotate === 180) {
              top = (documentHeight - e.y - e.height) * ratio * zoomLevel;
              left = (documentWidth - e.x - e.width) * ratio * zoomLevel;
              width = e.width * ratio * zoomLevel;
              height = e.height * ratio * zoomLevel;
            }
            else if (this.state.rotate === 270) {
              top = (documentWidth - e.x - e.width) * ratio * zoomLevel;
              left = e.y * ratio * zoomLevel;
              height = e.width * ratio * zoomLevel;
              width = e.height * ratio * zoomLevel;
            }

            d.style.display = 'block';
            d.style.position = 'absolute';
            d.style.top = top + "px";
            d.style.left = left + "px";
            d.style.width = width + "px";
            d.style.height = height + "px";
            d.style.backgroundColor = "#2485d8";
            d.style.opacity = "0.5";
            d.style.zIndex = "40";

            /* [Cloud-261] Temporarily remove the zoom and focus handles to reduce processing time
              this.viewer.current.onSetZoom(zoomLevel);
            */

            // *** Go to the highlight rectangle
            // this margin keep highlight item at center of pdf viewer
            const marginTop = (wrapper.clientHeight - e.height * ratio * zoomLevel) / 2;
            const marginLeft = (wrapper.clientWidth - e.width * ratio * zoomLevel) / 2;
            this.focusDocumentToPosition(left - marginLeft, top - marginTop);
          }
          return false;
        }
        else {
          this.hideHighLightElm();
          return true;
        }
      });
    }
  }

  modifyPdf = async () => {
    const { documentsStore, documentsStore: { pdfBytes, documentWidth, documentHeight } } = this.props;
    if (documentsStore.currentDocument?.inputFilePath && pdfBytes && pdfBytes?.byteLength) {
      const pdfDoc = await PDFDocument.load(pdfBytes);
      this.pdfViewerHeight = (this.pdfViewerWidth / documentWidth) * documentHeight;
      this.viewer.current.onUpdateWidth(this.pdfViewerWidth);
      this.viewer.current.onUpdateHeight(this.pdfViewerHeight);

      // const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
      const pdfDataUri = await pdfDoc.save();
      this.viewer.current.onUpdateContent(pdfDataUri);
    }
  }

  onUpdateUserScale = (scale) => {
    this.userScale = scale;
  }

  onUpdateUserRotate = (rotate) => {
    this.state.rotate = rotate;
  }

  focusDocumentToPosition = (x, y) => {
    var elmnt = document.getElementsByClassName("pdf-content")[0];
    const isIE = /MSIE|Trident/.test(window.navigator.userAgent);
    if (elmnt) {
      if (isIE) {
        elmnt.scrollTop = y;
        elmnt.scrollLeft = x;
      }
      else {
        elmnt.scrollTo({
          left: x,
          top: y,
          behavior: 'auto'
        });
      }
    }
  }

  trHandler = () => {
    // compute for table body
    let table = document.getElementsByTagName("table");
    if (table && table[0]) {
      let tableHead = document.getElementsByTagName("thead");
      if (tableHead && tableHead[0]) {
        let tableBody = document.getElementsByTagName("tbody");
        if (tableBody && tableBody[0]) {
          let tableBodyHeight = table[0].offsetHeight - tableHead[0].offsetHeight;

          // compute tr
          let trElements = document.getElementsByTagName("tr");
          let trHeight = 0;
          let num = 0;
          for (let element of trElements) {
            if (num !== 0) {
              trHeight += element.offsetHeight;
            }
            num++;
          }

          if (tableBodyHeight > trHeight) {
            tableHead[0].style.overflowY = "hidden";
          } else {
            tableHead[0].style.overflowY = "scroll";
          }
        }
      }
    }
  }

  cleanOldPage = () => {
    this.verifyAtLeastOneItem = false;
    this.verifiedList = [];
    let element = document.getElementById("btn-save-document");
    if (element) {
      element.disabled = true;
    }
  };

  requestNewPage = (page, force = false) => {
    this.userScale = null
    if (this.verifyAtLeastOneItem && !force) {
      this.openChangePageModal(page);
    } else if ((page || page === 0) && this.documents) {
      let __counter = 0;
      this.props.documentsStore.setCurrentPage(1);
      this.documents.forEach((element) => {
        if (__counter === page) {
          this.cleanOldPage();
          this.props.documentsStore.getDocumentById(element.id).then(() => {
            this.props.documentsStore.getFileData().then(() => {
              this.modifyPdf();
              this.viewer.current.onSetZoom(1);
              this.viewer.current.onRotateReset();
              this.viewer.current.resetPage();
              this.hideHighLightElm();
            });
            let dataHeader = this.props.documentsStore.currentOcrItems?.filter(
              currentOcrItem => currentOcrItem.tableName == '' || currentOcrItem.tableName == null);
            let isDisplayHeader = dataHeader && dataHeader.length > 0;
            this.setState({ defaultIndex: 0, isDisplayHeader });
          });
          this.props.history.push(SYSTEM_PATH.EDIT + element.id);
          this.setState({ isDisplayHeader: null });
        }
        __counter++;
      });
    }
  };

  openChangePageModal = (page) => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.CONFIRM,
      isOpen: true,
      header: t("edit.change_page_confirm_title"),
      onCancel: () => this.closeModal(MODAL_ID.CONFIRM),
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{t("edit.change_page_description")}</span>
          </div>
          <ModalFooter
            key={"modal-footer"}
            saveButtonText={t("edit.change_page_yes")}
            onConfirm={() => {
              this.closeModal();
              this.requestNewPage(page, true);
            }}
            cancelButtonText={t("edit.change_page_no")}
            onCancel={() => {
              this.closeModal();
              this.forceUpdate();
            }}
          />
        </div>
      ),
      type: MODAL_SIZE.SMALL,
    });
  };

  currentPage = () => {
    let currentPage = 0;
    let __counter = 0;
    let currentPageId = this.props.match.params?.id;
    if (this.documents && currentPageId) {
      this.documents.forEach((element) => {
        if (element.id === parseInt(currentPageId)) {
          currentPage = __counter;
        }
        __counter++;
      });
    }
    return currentPage;
  };

  componentDidMount() {
    const { documentsStore, commonStore: { settingStore: { getSetting, setting } } } = this.props;
    if (!setting) getSetting();
    documentsStore.setKeepFilterState(true);
    let id = this.props.match.params?.id;
    const sessionId = Browser.getSavedInfo("sessionId");
    if (!sessionId) {
      return;
    } else {
      if (id) {
        documentsStore.getDocumentById(id).then(() => {
          documentsStore.getFileData().then(() => {
            this.modifyPdf();
          });
          documentsStore.setCurrentPage(1);
          let dataHeader = documentsStore.currentOcrItems?.filter(currentOcrItem => currentOcrItem.tableName == '' || currentOcrItem.tableName == null);
          let isDisplayHeader = dataHeader && dataHeader.length > 0;
          this.setState({ defaultIndex: 0, isDisplayHeader });
        });
      }
      let element = document.getElementById("btn-save-document");
      if (element) {
        element.disabled = true;
      }
      const documentsList = Browser.getSavedInfo("documents");
      if (documentsList) {
        this.documents = JSON.parse(localStorage.getItem("documents"));
      } else {
        this.props.documentsStore.getDocuments().then(() => {
          this.documents = JSON.parse(localStorage.getItem("documents"));
        });
      }

      if (localStorage.getItem("documents")) {
        this.documents = JSON.parse(localStorage.getItem("documents"));

        // Remove error/processing items
        this.documents = this.documents.filter((element) => {
          if (element.status !== DOCUMENT_STATUS.PROCESSING && element.status !== DOCUMENT_STATUS.ERROR) {
            return element;
          }
          return null;
        });
      }
    }
  }

  getCurrentDocument = async () => {
    let id = this.props.match.params?.id;
    if (id) {
      await this.props.documentsStore.getDocumentById(id, true);
    }
  }

  handleVerifyItems = (e, id) => {
    const { documentsStore } = this.props;
    documentsStore.updateValueChange(
      id,
      e.target.value
    );
    if (!this.verifyAtLeastOneItem) {
      this.verifyAtLeastOneItem = true;
      let element = document.getElementById("btn-save-document");
      if (element && !this.isConfirmStatus()) {
        element.disabled = false;
        element.removeAttribute("title");
      }
    }
    this.renderVerifyItemById(id);
    this.verifiedList.push(id);
  };

  handleHightLightItem = (id, isHighLight = false) => {
    const startTime = new Date().getTime();
    const { documentsStore: { setHightlightedItem } } = this.props;
    let elements = document.getElementsByClassName("Highlight__part__" + id);
    if (elements && elements[0]) {
      if (isHighLight) {
        elements[0].style.border = "2px solid red";
      } else {
        elements[0].style.border = "unset";
      }
    }
    setHightlightedItem(id);
    this.drawHighLightElm(this.userScale);

    console.log('highlight duration: ', new Date().getTime() - startTime);
  }

  renderVerifyItemById = (id) => {
    /* Comment css tick to verify item */
    // let element = document.getElementById("checkbox_" + id);
    // if (element) {
    //   element.innerHTML = "&#10004;";
    // }
    let elementX = document.getElementById("input_" + id);
    if (elementX) {
      elementX.style.border = '2px solid green';
    }
  };

  renderVerifyItems = () => {
    this.verifiedList.forEach((id) => {
      this.renderVerifyItemById(id);
    });

    // There are cases where thead has scrollbar but tbody does not and vice versa.
    // So need to synchronize the scrollbar for thead and tbody so that columns of table is not skewed.
    setTimeout(
      () => {
        const theads = window.$(".div-tab table thead");
        const tbodys = window.$(".div-tab table tbody");
        for (var i = 0; i < theads.length; i++) {
          if (theads[i] && tbodys[i]) {
            if (tbodys[i].scrollHeight > tbodys[i].clientHeight) {
              window.$(theads[i]).css({ "overflow-y": "scroll" });
            }
            else {
              window.$(theads[i]).css({ "overflow-y": "hidden" });
            }
          }
        }
      },
      3
    );
  };

  componentDidUpdate = () => {
    this.renderVerifyItems();
    this.trHandler();
    let element = document.getElementById("btn-save-document");
    if (element && !element.disabled) {
      element.removeAttribute("title");
    }
    // this.modifyPdf();
    // Attach tooltip
    window.$('[data-toggle="tooltip"]').tooltip({
      // container: '.edit-screen',
      trigger: 'hover',
    })
  };

  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hide();
  };

  openCancelModal = () => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.CONFIRM,
      isOpen: true,
      header: t("edit.cancel_confirm_title"),
      onCancel: () => this.closeModal(MODAL_ID.CONFIRM),
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>
              {t("edit.cancel_description")}
            </span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t("edit.cancel_yes")}
            onConfirm={() => {
              this.closeModal();
              this.props.history.push(this.documentTransferData());
            }}
            cancelButtonText={t("edit.cancel_no")}
            onCancel={this.closeModal}
          />
        </div>
      ),
      type: MODAL_SIZE.SMALL
    });
  };

  openModalToUpdateCurrentDocument = () => {
    const { modalStore, t, documentsStore } = this.props;
    modalStore.show({
      id: MODAL_ID.CONFIRM,
      isOpen: true,
      header: t("edit.update_confirm_title"),
      onCancel: () => {
        this.closeModal(MODAL_ID.CONFIRM);
      },
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{t("edit.update_description")}</span>
          </div>
          <ModalFooter
            key={"modal-footer"}
            saveButtonText={t("edit.update_yes")}
            onConfirm={() => {
              this.closeModal();
              let id = this.props.match.params?.id;
              if (id) {
                documentsStore.getDocumentById(id);
              }
            }}
            cancelButtonText={t("edit.update_no")}
            onCancel={() => {
              this.closeModal();
            }}
          />
        </div>
      ),
      type: MODAL_SIZE.SMALL,
    });
  };

  columns = () => {
    const { t, documentsStore, commonStore: { settingStore: { setting } } } = this.props;
    let cols = [
      {
        Header: t("edit.item_name"),
        accessor: "key",
        Cell: ({ row }) => {
          const { prebuiltSetting } = documentsStore;
          let header = row?.original?.key;
          if (prebuiltSetting && prebuiltSetting.settingBody && prebuiltSetting.settingBody.hasOwnProperty(header)) {
            let converted = prebuiltSetting.settingBody[header];
            if (converted && converted != "") {
              header = converted;
            }
          }
          return header;
        },
        width: TABLE_ROW_WIDTH.NORMAL_30_PERCENT,
        disableSortBy: true,
        className: 'item-name',
      },
      {
        Header: t("edit.output_value"),
        accessor: "value",
        Cell: ({ row }) => {
          const { confidence_threshold_1, confidence_threshold_2 } = setting;
          let className = "level-" + Helper.calcConfigLevel(confidence_threshold_1, confidence_threshold_2, parseInt(row.original?.confidence));
          return (
            <span className="ar-tooltip"
              data-toggle="tooltip"
              data-placement="top"
              title={t("edit.item_confidence") + ': ' + row.original?.confidence}>
              <input
                tabIndex={row.index + 100}
                key={row.original?.itemsjshid}
                className={className}
                type="text"
                defaultValue={row.original?.value ? row.original?.value : ""}
                id={"input_" + row.original?.itemsjshid}
                onChange={(e) =>
                  this.handleVerifyItems(e, row.original?.itemsjshid)
                }
                onFocus={() => this.handleHightLightItem(row.original?.itemsjshid, true)}
                onKeyDown={(e) => this.onKeyDown(e, 3)}
                disabled={this.isConfirmStatus()}
              />
            </span>
          );
        },
        width: documentsStore.configFileEdition != CONFIG_FILE_EDITION.ITEM ? '70%' : '60%',
        disableSortBy: true,
        className: 'output-value'
      },
      /* Task 122 - hide two columns
      // {
      //   Header: t("edit.item_confidence"),
      //   accessor: "confidence",
      //   width: "15%",
      //   disableSortBy: true,
      // },
      // {
      //   Header: t("edit.verification"),
      //   className: "edit-verification",
      //   Cell: ({ row }) => (
      //     <div
      //       id={"checkbox_" + row.original?.itemsjshid}
      //       className="verify-text"
      //     ></div>
      //   ),
      //   width: "15%",
      //   disableSortBy: true,
      // },
      */
    ];
    if (documentsStore.configFileEdition == CONFIG_FILE_EDITION.ITEM) {
      cols.push({
        id: 'action',
        className: 'admin-action',
        Cell: ({ row: { original: { itemsjshid } } }) => {
          return <div>
            {
              !this.isConfirmStatus() &&
              < i className="fas fa-minus-circle fa-2x ar-tooltip"
                data-toggle="tooltip"
                data-placement="left"
                title={t("edit.delete_line")}
                onClick={() => this.onConfirmDeleteById(itemsjshid)}>
              </i>
            }
          </div>
        },
        width: '10%',
      })
    }
    return cols;
  };
  onConfirmDeleteById = (itemsjshid) => {
    this.props.documentsStore.removeItem(itemsjshid);
    this.closeModal();
    let element = document.getElementById("btn-save-document");
    if (element && !this.isConfirmStatus()) {
      element.disabled = false;
      element.removeAttribute("title");
    }
    this.verifyAtLeastOneItem = true; // to display warning change
  }
  onConfirmDeleteDetailItemById = (dataRow) => {
    var list_itemsjshid = [];
    Object.keys(dataRow).forEach(function (key) {
      list_itemsjshid.push(dataRow[key].itemsjshid);
    });

    this.props.documentsStore.removeItemDetail(list_itemsjshid);
    this.closeModal();
    let element = document.getElementById("btn-save-document");
    if (element && !this.isConfirmStatus()) {
      element.disabled = false;
      element.removeAttribute("title");
    }
    this.verifyAtLeastOneItem = true; // to display warning change
  }
  onConfirmAdd = () => {
    const itemNameAdd = this.state.itemNameAdd;
    const { t } = this.props;
    if (itemNameAdd == '') {
      document.getElementById("required_msg").textContent = t("validate.field_is_empty");
    } else {
      this.props.documentsStore.addItem(itemNameAdd);
      this.closeModal();
      let element = document.getElementById("btn-save-document");
      if (element && !this.isConfirmStatus()) {
        element.disabled = false;
        element.removeAttribute("title");
      }
      this.setState({
        itemNameAdd: ''
      });
      this.verifyAtLeastOneItem = true; // to display warning change
    }
  }
  onAddDetailItem = (dataRow) => {
    var first_itemsjshid = dataRow[Object.keys(dataRow)[0]].itemsjshid;
    this.props.documentsStore.insertItemDetailByRowBelow(first_itemsjshid, dataRow);
    this.closeModal();
    let element = document.getElementById("btn-save-document");
    if (element && !this.isConfirmStatus()) {
      element.disabled = false;
      element.removeAttribute("title");
    }
    this.verifyAtLeastOneItem = true; // to display warning change
  }
  onAddDetailItemInLast = (tabTitle) => {
    // var dataLastRow = data[data.length-1];
    // var array_key = Object.keys(dataLastRow);
    // var last_itemsjshid = dataLastRow[array_key[array_key.length-1]].itemsjshid;
    this.props.documentsStore.insertItemDetailInLast(tabTitle);
    this.closeModal();
    let element = document.getElementById("btn-save-document");
    if (element && !this.isConfirmStatus()) {
      element.disabled = false;
      element.removeAttribute("title");
    }
    this.verifyAtLeastOneItem = true; // to display warning change
  }

  openComfirmModal = (itemsjshid, isDeleteDetailItem, dataRow) => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.REMOVE,
      isOpen: true,
      header: t('definition.remove_confirm'),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{t('edit.remove_confirm_msg')}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t('individual_model_mng.remove')}
            onConfirm={() => isDeleteDetailItem ? this.onConfirmDeleteDetailItemById(dataRow) : this.onConfirmDeleteById(itemsjshid)}
            cancelButtonText={t('definition.cancel')}
            onCancel={this.closeModal}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'small'
    });
  };

  openAddModal = () => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.CREATE_ITEM,
      isOpen: true,
      header: t('edit.add_new_title'),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body modal-add-item">
            <div className="row">
              <div className="col-sm-1" />
              <div className="col-sm-4 font-bold">
                <span className="color-red">*</span>
                {t("setting_definition.required")}
              </div>
            </div>
            <div className="row" style={{ paddingTop: "15px" }}>
              <div className="col-sm-1" />
              <div className="col-sm-4 font-bold">{t("edit.item_name")}<span className="color-red">*</span></div>
              <div className="col-sm-6" id="div_item_name">
                <input name="item_name"
                  type="text"
                  maxLength={50}
                  onChange={(value) => this.onChangeItemNameNew(value.target.value)}
                  placeholder={t("edit.item_name")}
                />
                <span className="color-red" id="required_msg"></span>
              </div>
            </div>
          </div>

          <ModalFooter key={"modal-footer"}
            saveButtonText={t('user.create')}
            onConfirm={() => this.onConfirmAdd()}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'medium'
    });
  };
  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hideAll();
  };
  zoomIn = () => {
    this.viewer.current.setScale(2);
  }

  handleChangePage = (page) => {
    this.props.documentsStore.setCurrentPage(page);
    this.hideHighLightElm();
  }
  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }
  convertDataTableDetail(dataDetail) {
    var object = {};
    var new_arr = [];
    if (dataDetail) {
      dataDetail.forEach((data, index, array) => {
        if (object.hasOwnProperty(data.key)) {
          new_arr.push(object);
          object = {};
          object[data.key] = { 'value': data.value, 'confidence': data.confidence, 'itemsjshid': data.itemsjshid };
        } else {
          object[data.key] = { 'value': data.value, 'confidence': data.confidence, 'itemsjshid': data.itemsjshid };
        }
        if (index === array.length - 1) {
          new_arr.push(object);
        }
      })
    }
    return new_arr;
  }
  calcCellWidth(txt) {
    var span = document.createElement('span');
    span.style.position = 'absolute';
    span.style.top = '-1000px';
    span.style.left = '-1000px';
    span.style.whiteSpace = 'nowrap';
    span.innerHTML = txt;
    span.style.fontFamily = '"MS PGothic",Osaka,Arial,sans-serif';
    span.style.fontSize = '14px';
    document.body.appendChild(span);
    var width = span.clientWidth + 20.6;
    span.parentElement.removeChild(span);
    return width;
  }
  calcHeaderWidth(txt) {
    var span = document.createElement('span');
    span.style.position = 'absolute';
    span.style.top = '-1000px';
    span.style.left = '-1000px';
    span.style.whiteSpace = 'nowrap';
    span.innerHTML = txt;
    span.style.fontFamily = '"Noto Sans JP",sans-serif';
    span.style.fontSize = '14px';
    span.style.fontWeight = 'bold';
    document.body.appendChild(span);
    var width = span.clientWidth + 12.6;
    span.parentElement.removeChild(span);
    return width;
  }
  tabPanelForDetail(grouped, i) {
    const { t, documentsStore, commonStore: { settingStore: { setting } } } = this.props;
    let dataHeaderDetail = documentsStore.dataHeaderDetail
      ? documentsStore.dataHeaderDetail
      : {};
    let currentPage = documentsStore.currentPage
      ? documentsStore.currentPage
      : 0;
    var dataDetail = grouped.get(i); // group data by tableName

    var dataTable = this.convertDataTableDetail(dataDetail); // convert data for table detail
    // var header_detail = [...new Set(dataDetail.map(item => item.key))];

    var minWidth = this.calcCellWidth('0');
    var maxWidth = this.calcCellWidth('ああああああああああああああああああああ'); // 20文字
    var widths = {}
    dataHeaderDetail[currentPage - 1][i].forEach((data) => {
      widths[data] = minWidth;
      var len = this.calcHeaderWidth(data);
      widths[data] = len > widths[data] ? len : widths[data];
      dataDetail.filter(detail => detail.key == data).forEach((detail) => {
        var len = this.calcCellWidth(detail.value);
        widths[data] = len > widths[data] ? len : widths[data];
      });
      widths[data] = maxWidth < widths[data] ? maxWidth : widths[data];
    });

    var columns = [];
    dataHeaderDetail[currentPage - 1][i].forEach((data) => {
      var obj = {
        accessor: data,
        Header: () => {
          const { prebuiltSetting } = documentsStore;
          let header = data;
          if (prebuiltSetting && prebuiltSetting.settingBody && prebuiltSetting.settingBody.hasOwnProperty(data)) {
            let converted = prebuiltSetting.settingBody[data];
            if (converted && converted != "") {
              header = converted;
            }
          }
          return header;
        },
        Cell: ({ row: { original } }) => {
          const { confidence_threshold_1, confidence_threshold_2 } = setting;
          let className = "level-" + Helper.calcConfigLevel(confidence_threshold_1, confidence_threshold_2, parseInt(original[data]?.confidence));
          return (
            <span className="ar-tooltip table-column"
              data-toggle="tooltip"
              data-placement="top"
              title={t("edit.item_confidence") + ': ' + original[data]?.confidence}>
              <input
                key={original[data]?.itemsjshid}
                className={className}
                type="text"
                defaultValue={original[data]?.value ? original[data]?.value : ""}
                id={"input_" + original[data]?.itemsjshid}
                onChange={(e) =>
                  this.handleVerifyItems(e, original[data]?.itemsjshid)
                }
                onFocus={() => this.handleHightLightItem(original[data]?.itemsjshid, true)}
                onKeyDown={(e) => this.onKeyDown(e, dataHeaderDetail[currentPage - 1][i].length)}
                disabled={this.isConfirmStatus()}
              />
            </span>
          );
        },
        disableSortBy: true,
        width: widths[data],
        minWidth: minWidth,
        maxWidth: maxWidth,
      }
      columns.push(obj);
      return columns;
    });
    // add , delete action
    columns.push({
      id: 'action',
      className: 'admin-action',
      width: 80,
      disableResizing: true,
      Cell: ({ row }) => {
        return (<div>
          {
            !this.isConfirmStatus() &&
            <i className="fas fa-plus-circle fa-2x ar-tooltip" style={{ color: "#377fa8", cursor: "pointer", marginRight: "10px" }}
              data-toggle="tooltip"
              data-placement="left"
              title={t("edit.add_one_line_above")} onClick={() => this.onAddDetailItem(dataTable[row.index])}></i>
          }{
            !this.isConfirmStatus() &&
            <i className="fas fa-minus-circle fa-2x ar-tooltip"
              data-toggle="tooltip"
              data-placement="left"
              title={t("edit.delete_line")} onClick={() => this.onConfirmDeleteDetailItemById(dataTable[row.index])}></i>
          }
        </div>
        );
      },
    })
    return { columns: columns, dataTable: dataTable };
  }

  saveCurrentDocument = () => {
    if (this.verifyAtLeastOneItem) {
      const { t, documentsStore } = this.props;
      documentsStore.saveCurrentDocument(this.props.history, this.getCurrentDocument, this.openModalToUpdateCurrentDocument)
      this.verifyAtLeastOneItem = false;
      let element = document.getElementById("btn-save-document");
      if (element) {
        element.disabled = true;
        element.setAttribute("title", t("edit.verify_at_least_one_item_to_save"));
      }
      this.verifiedList.splice(0);
    }
  }

  onKeyDown = (e, totalCell) => {
    let key;
    let isShift;
    if (window.event) {
      key = window.event.keyCode;
      isShift = !!window.event.shiftKey; // typecast to boolean
    } else {
      key = e.which;
      isShift = !!e.shiftKey;
    }
    let idx = e.target.parentElement.parentElement.cellIndex;
    const UP_ARROW = 38;
    const DOWN_ARROW = 40;
    const LEFT_ARROW = 37;
    const RIGHT_ARROW = 39;
    const TOTAL_CELLS_BY_ROW = totalCell;
    if (isShift) {
      switch (key) {
        case LEFT_ARROW:
          if (e.target.parentElement.parentElement.previousElementSibling &&
            e.target.parentElement.parentElement.previousElementSibling.childNodes[0] &&
            e.target.parentElement.parentElement.previousElementSibling.childNodes[0].childNodes[0]) {
            e.target.parentElement.parentElement.previousElementSibling.childNodes[0].childNodes[0].focus();
          }
          break;
        case RIGHT_ARROW:
          if (e.target.parentElement.parentElement.nextElementSibling &&
            e.target.parentElement.parentElement.nextElementSibling.childNodes[0] &&
            e.target.parentElement.parentElement.nextElementSibling.childNodes[0].childNodes[0]) {
            e.target.parentElement.parentElement.nextElementSibling.childNodes[0].childNodes[0].focus();
          }
          break;
        case DOWN_ARROW:
          const nextRow = e.target.parentElement.parentElement.parentElement.nextElementSibling
          if (nextRow &&
            nextRow.childNodes[idx % TOTAL_CELLS_BY_ROW] &&
            nextRow.childNodes[idx % TOTAL_CELLS_BY_ROW].childNodes[0] &&
            nextRow.childNodes[idx % TOTAL_CELLS_BY_ROW].childNodes[0].childNodes[0]) {
            nextRow.childNodes[idx % TOTAL_CELLS_BY_ROW].childNodes[0].childNodes[0].focus();
          }
          break;
        case UP_ARROW:
          const previousRow = e.target.parentElement.parentElement.parentElement.previousElementSibling
          if (previousRow &&
            previousRow.childNodes[idx % TOTAL_CELLS_BY_ROW] &&
            previousRow.childNodes[idx % TOTAL_CELLS_BY_ROW].childNodes[0] &&
            previousRow.childNodes[idx % TOTAL_CELLS_BY_ROW].childNodes[0].childNodes[0]) {
            previousRow.childNodes[idx % TOTAL_CELLS_BY_ROW].childNodes[0].childNodes[0].focus();
          }
          break;
        default:
          break;
      }
    }
  }

  getPreviewPdfPath(inputFilePath) {
    var strPath = "" + inputFilePath;
    var prefix = strPath.substring(0, strPath.indexOf('/'));
    if (CONFIG.preview_pdf_container_name == prefix) {
      return CONFIG.container_domain + inputFilePath;
    } else {
      return CONFIG.file_domain + inputFilePath;
    }
  }

  isConfirmStatus = () => {
    const { documentsStore } = this.props;
    return documentsStore.currentDocument?.status == DOCUMENT_STATUS.CONFIRMED
      || documentsStore.currentDocument?.status == DOCUMENT_STATUS.DOWNLOADED;
  }

  confirmCurrentDocument = () => {
    const { t, documentsStore } = this.props;
    documentsStore.confirmCurrentDocument(this.props.history, this.getCurrentDocument, this.openModalToUpdateCurrentDocument)
    this.verifyAtLeastOneItem = false;
    let element = document.getElementById("btn-save-document");
    if (element) {
      element.disabled = true;
      element.setAttribute("title", t("edit.unconfirm_before_edit"));
    }
    this.verifiedList.splice(0);
  }

  unconfirmCurrentDocument = () => {
    const { t, documentsStore } = this.props;
    documentsStore.unconfirmCurrentDocument(this.props.history, this.getCurrentDocument)
    this.verifyAtLeastOneItem = false;
    let element = document.getElementById("btn-save-document");
    if (element) {
      element.disabled = true;
      element.setAttribute("title", t("edit.verify_at_least_one_item_to_save"));
    }
    this.verifiedList.splice(0);
  }

  getConfigFileName = () => {
    const { t, documentsStore } = this.props;
    if (documentsStore.currentDocument?.configFileEdition == CONFIG_FILE_EDITION.PREBUILT) {
      switch (documentsStore.currentDocument?.configFileName) {
        case PREBUILT_MODELS.INVOICE:
          return t("download.prebuilt_invoice");
        case PREBUILT_MODELS.RECEIPT:
          return t("download.prebuilt_receipt");
        case PREBUILT_MODELS.CLAUDE_3_5:
          return t("download.prebuilt_claude_3_5");
        default:
          return EMPTY_FORMAT_CELL;
      }
    } else {
      return documentsStore.currentDocument?.configFileName;
    }
  }

  render() {
    const { t, documentsStore } = this.props;
    let currentOcrItems = documentsStore.currentOcrItems
      ? documentsStore.currentOcrItems
      : [];
    let configFileEdition = documentsStore.configFileEdition
      ? documentsStore.configFileEdition
      : '';
    let tabTitle = documentsStore.tabTitle
      ? documentsStore.tabTitle
      : {};
    let currentPage = documentsStore.currentPage
      ? documentsStore.currentPage
      : 1;
    var tab_detail = null;
    var tab_panel_detail = null;
    var dataHeader = [];
    if ((Object.prototype.toString.call(currentOcrItems) === '[object Array]' && currentOcrItems?.length) || (Object.keys(tabTitle)?.length !== 0 && tabTitle[currentPage - 1]?.length)) {
      var grouped = this.groupBy(currentOcrItems, data => data.tableName);
      tab_detail = tabTitle[currentPage - 1]?.map(function (i) {
        let table = i;
        if (documentsStore.currentDocument?.configFileEdition == CONFIG_FILE_EDITION.PREBUILT
          && documentsStore.currentDocument?.configFileName != PREBUILT_MODELS.CLAUDE_3_5) {
          switch (i) {
            case "Items":
              table = t("edit.table.prebuilt.items");
              break;
            case "TaxDetails":
              table = t("edit.table.prebuilt.tax_details");
              break;
          }
        } else if (documentsStore.currentDocument?.configFileEdition == CONFIG_FILE_EDITION.PREBUILT
          && documentsStore.currentDocument?.configFileName == PREBUILT_MODELS.CLAUDE_3_5) {
          table = table.replace("Detail", t("edit.table.prebuilt.claude35.detail"));
        }
        return (
          <Tab>{table}</Tab>
        );
      });
      tab_panel_detail = tabTitle[currentPage - 1]?.map(function (i) {
        var data = this.tabPanelForDetail(grouped, i)
        return (
          <TabPanel>
            <Table
              disablePaging={true}
              columns={data.columns}
              data={data.dataTable ? data.dataTable : []}
              disableSelectionPageSize={true}
              className="tbl-detail"
              tableFooter={
                <tr>
                  <td colSpan={3}>
                    {
                      !this.isConfirmStatus() &&
                      <i className="fas fa-plus-circle fa-2x ar-tooltip" style={{ color: "#377fa8", cursor: "pointer" }}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={t("edit.add_one_line")} onClick={() => this.onAddDetailItemInLast(i)}></i>
                    }
                  </td>
                </tr>
              }
              resizable={true}
            />
          </TabPanel>
        );
      }.bind(this));
      dataHeader = currentOcrItems.filter(currentOcrItem => currentOcrItem.tableName == '' || currentOcrItem.tableName == null);
    }

    // When initializing, display the pdf page with a width equal to 100% of the wrapper
    const wrapper = document.getElementsByClassName("my-pdf-viewer-wrapper")[0];
    if (!this.pdfViewerWidth) {
      this.pdfViewerWidth = wrapper?.clientWidth
    }

    return (
      <ContainerBox className="edit-screen table-container" headerTitle={t("edit.screen")}>
        <div className="form-group action-box se-btn-pannel">
          <div className="col-sm-3 se-btn-next-prev">
            <ReactPaging
              forcePage={this.currentPage()}
              pageCount={this.documents?.length}
              onPageChange={(e) => {
                this.requestNewPage(e.selected);
              }}
              pageRangeDisplayed={0}
              marginPagesDisplayed={0}
              breakLabel={""}
              className="active-item"
              useCustomForcePage={true}
            />
          </div>
          <div className="col-sm-4 se-btn-box">
            <button
              className="cancel-btn-style btn btn-danger mb-3"
              type="button"
              onClick={this.handleBackButton}
            >
              {t("edit.cancel")}
            </button>
            <button
              title={this.isConfirmStatus() ? t("edit.unconfirm_before_edit") : t("edit.verify_at_least_one_item_to_save")}
              className="save-btn-style btn btn-primary mb-3"
              type="button"
              id="btn-save-document"
              onClick={this.saveCurrentDocument}
            >
              {t("edit.save")}
            </button>
            {
              this.isConfirmStatus() ?
                <button
                  className="unconfirm-btn-style btn btn-primary mb-3"
                  type="button"
                  id="btn-unconfirm-document"
                  onClick={this.unconfirmCurrentDocument}
                >
                  {t("edit.unconfirm")}
                </button>
                :
                <button
                  className="confirm-btn-style btn btn-primary mb-3"
                  type="button"
                  id="btn-confirm-document"
                  onClick={this.confirmCurrentDocument}
                >
                  {t("edit.confirm")}
                </button>
            }
          </div>
          <div className="col-sm-5 se-text-area-box">
            <div className="row se-text-area-box-row">
              <div className="col-sm-12">
                <div className="se-text-area-box-item">
                  <div className="se-text-area-box-item-label">{t("edit.info_file_name")}</div>
                  <div className="se-text-area-box-item-content" title={documentsStore.currentDocument?.inputFileName + " (" + documentsStore.currentDocument?.pageNo + ")"}>: {documentsStore.currentDocument?.inputFileName} ({documentsStore.currentDocument?.pageNo})</div>
                </div>
                <div className="se-text-area-box-item">
                  <div className="se-text-area-box-item-label">{t("edit.info_definition_file")}</div>
                  <div className="se-text-area-box-item-content" title={this.getConfigFileName()}>: {this.getConfigFileName()}</div>
                </div>
                <div className="se-text-area-box-item">
                  <div className="se-text-area-box-item-label">{t("edit.info_status")}</div>
                  <div className="se-text-area-box-item-content" title={documentsStore.currentDocument?.status ? t("download.status_" + documentsStore.currentDocument?.status) : ""}>: {documentsStore.currentDocument?.status ? t("download.status_" + documentsStore.currentDocument?.status) : ""}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-group screen-edit-body">
          <ResizablePanels displayDirection="row" width="100%" panelsSize={[50, 50]} sizeUnitMeasure="%" resizerColor="#ffffff" resizerSize="5px" >
            <div className="document-file">
              <MyPdfViewer
                totalPage={documentsStore.currentDocument?.totalPage}
                initalData={this.getPreviewPdfPath(documentsStore.currentDocument?.inputFilePath)}
                ref={this.viewer}
                onUpdateUserScale={this.onUpdateUserScale}
                onChangePage={this.handleChangePage}
                pdfViewerWidth={this.pdfViewerWidth}
                pdfViewerHeight={this.pdfViewerHeight}
                drawHighLightElm={this.drawHighLightElm}
                onUpdateUserRotate={this.onUpdateUserRotate}
              ></MyPdfViewer>
            </div>
            <div className="document-item-info" style={{ overflow: "auto", height: "100%" }}>
              <div className="div-tab">
                <Tabs defaultIndex={this.state.defaultIndex} forceRenderTabPanel={true} onSelect={index => { this.renderVerifyItems(); }}>
                  <TabList>
                    {
                      (this.state.isDisplayHeader === null || this.state.isDisplayHeader) &&
                      <Tab>{documentsStore.currentDocument?.configFileEdition == CONFIG_FILE_EDITION.PREBUILT
                        ? t("edit.table.prebuilt.header") : "Header"}</Tab>
                    }
                    {tab_detail}
                  </TabList>
                  {
                    (this.state.isDisplayHeader === null || this.state.isDisplayHeader) &&
                    <TabPanel>
                      <Table
                        disablePaging={true}
                        columns={this.columns()}
                        data={dataHeader ? dataHeader : []}
                        disableSelectionPageSize={true}
                        tableFooter={
                          configFileEdition = ! '' && configFileEdition == CONFIG_FILE_EDITION.ITEM ?
                            <tr>
                              <td colSpan={3}>
                                {
                                  !this.isConfirmStatus() &&
                                  <i className="fas fa-plus-circle fa-2x ar-tooltip" style={{ color: "#377fa8", cursor: "pointer" }}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={t("edit.add_one_line")} onClick={() => this.openAddModal()}>
                                  </i>
                                }
                              </td>
                            </tr>
                            : ''
                        }
                        className="tbl-header"
                      />
                    </TabPanel>
                  }
                  {tab_panel_detail ? tab_panel_detail : null}
                </Tabs>
              </div>
            </div>
          </ResizablePanels>
        </div>
      </ContainerBox>
    );
  }

}

export default withRouter(withTranslation()(ScreenEdit));